import * as React from "react"
import styled from 'styled-components'
import { Image } from './Images'
import Title from "./Title"
import { useTranslation } from "react-i18next"

import LeftArrow from '../images/home/services/arrow-left.svg'
import RightArrow from '../images/home/services/arrow-right.svg'
import Slider from "react-slick"


const Clients = ({ data }) => {

    function NextArrow(props) {
        const { className, onClick, onKeyUp } = props
        return (
            <div className={className} onClick={onClick} onKeyUp={onKeyUp} role='button' tabIndex='0'>
                <RightArrow />
            </div>
        )
    }

    function PrevArrow(props) {
        const { className, onClick, onKeyUp } = props
        return (
            <div className={className} onClick={onClick} onKeyUp={onKeyUp} role='button' tabIndex='0'>
                <LeftArrow />
            </div>
        )
    }

    const sliderSettings = {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
    const { t } = useTranslation()

    return (
        <ClientsStyled>
            <div className='wrapper'>

                <Title
                    title={data.clients.title}
                />

                <Slider {...sliderSettings}>
                    {data.clientsSlide.map((data, i) => (
                        <div className="slide" key={"clientes" + i}>
                            <div className="clientes" >
                                <Image src={data.img} className="image" alt={data.alt && data.alt} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

        </ClientsStyled>
    )
}

export default Clients

const ClientsStyled = styled.div`

.wrapper{
    max-width:1920px;
    margin:0 auto;
    padding:clamp(30px,15vw,80px) 5%;

    .clientes {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 5rem 15%;
        align-items: center;
        flex-wrap:wrap;
        max-width:1200px;

        .image {
            width: 100%;
            max-width: 160px;
        }
    }

    .slick-slider{
        overflow:hidden;
        position:relative;

        .slick-arrow{
            position: absolute;
            top: 50%;
            transform:translateY(-50%);
            cursor:pointer;
            z-index:1;
        }

        .slick-prev{
            left:1.5rem;
        }

        .slick-next{
            right:1.5rem;
        }

        .slick-disabled{
            opacity:0;
            transition: opacity 0.3s;
        }

        .slick-track{
            display:flex;
        }
    }   
}
`