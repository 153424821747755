import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { connect } from 'react-redux'
import styled from "styled-components"
import { color, media } from "../components/Styles"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Accordion from 'react-bootstrap/Accordion';
import Clients from "../components/Clients"
import TitleBanner from "../components/TitleBanner/TitleBanner"


const ProductsPage = ({ products, categories }) => {
    const { t } = useTranslation()


    var parents = []
    var productsGroupBy = []

    categories.filter((category) => {
        if (category.parentDatabaseId === null && category.slug !== 'uncategorized' && category.slug !== 'servicos') {
            categories.map((child) => {
                if (child.parentDatabaseId == category.databaseId) {
                    if (parents[category.databaseId]) {
                        if (parents[category.databaseId].children.length <= 0)
                            parents[category.databaseId] = {
                                "me": category,
                                "children": [child]
                            }
                        else
                            parents[category.databaseId].children.push(child)
                    }
                    else {
                        parents[category.databaseId] = {
                            "me": category,
                            "children": [child]
                        }
                        productsGroupBy[category.databaseId] = []
                        products.filter(product => product.productCategories.nodes.filter(cat => {
                            cat.slug === category.slug && productsGroupBy[category.databaseId].push(product)
                        }))
                        productsGroupBy[category.databaseId] = productsGroupBy[category.databaseId].slice(0, 4)
                    }
                }
            })
        }
    });


    return (
        <Layout>
            <Seo title="PRODUTOS | ENOMAC" />
            <TitleBanner
                image='header-footer/imagens-banner/banners-maquinaria.png'
                alt='Enomac'
                logo
                data={t("products", { returnObjects: true }).product}
            />
            <CategoryStyled>
                <div className="filter">
                    <div className="filteroption">

                        <Accordion className="accordion-container" defaultActiveKey={parents[935]}>
                            {parents.map((parent, index) => (
                                <Accordion.Item eventKey={index} className="container-item">
                                    <Accordion.Header className="container-header">
                                        <h3>
                                            {parent.me.name}
                                        </h3>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {parent.children.map((child, i) => {
                                            return (
                                                <div key={i}>
                                                    <Link to={child.link}>
                                                        <p className="optiontxt">{child.name}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>

                    </div>
                </div>
            </CategoryStyled>
            {/* 
            <Products
                products={products}

            />
            */}

            <Clients data={t("home", { returnObjects: true })} />
        </Layout >
    )
}

export default connect(state => ({
    products: state.app.products,
    categories: state.app.categories
}), null)(ProductsPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "products"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`


const CategoryStyled = styled.div`

    .filter {
        padding: clamp(30px,15vw,0px) 5%;
        

        .filtertxt {
            display: flex;
            justify-content: center;
            font-weight: 800;
            text-transform: uppercase;
            color: #0000004f;
        }

        .filteroption {
            display: flex;
            justify-content: center;
            gap: 4%;
            padding: 3rem 1%;
            cursor: pointer;
            border-bottom: 2px solid ${color.yellow};
            flex-wrap: wrap;
            flex-direction: column;

            .accordion-container {
                display: flex;
                justify-content: space-between;

                ${media.l`
                    flex-direction: column;
                `}

                .container-item{
                    border: none;

                    .container-header {
                        h3 {
                            text-transform: uppercase;
                            font-family: montserrat;
                            margin-left: auto;
                        }
                    }
                }
            }

            .optiontxt {
                border: 1px solid black;
                padding: 7px 30px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                display: flex;
                gap: 5%;
                justify-content: center;


                : hover {
                    background-color: ${color.yellow};
                    color: ${color.white};
                    border: ${color.yellow};
                    transition: all .35s;
                    
                }
            }

            .optiontxt {
                border: none;
                padding: 7px 30px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                display: flex;
                gap: 5%;

                : hover {
                    background-color: ${color.yellow};
                    color: ${color.white};
                    border: ${color.yellow};
                    transition: all .35s;
                    
                }
            }

            .option-check {
                padding: 7px 30px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                display: flex;
                gap: 5%;
                background-color: ${color.yellow};
                color: ${color.white}

                
            }
        }

    }
`
